import React, { useState } from "react";
import styled from "styled-components";
import Iframe from "react-iframe";
import { TextField } from "@material-ui/core";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const AskImam = (props) => {
  const [contactState, setContactState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");

  const onNameChange = (event) => {
    setContactState({ name: event.target.value });
  };

  const onEmailChange = (event) => {
    setContactState({ email: event.target.value });
  };

  const onSubjectChange = (event) => {
    setContactState({ subject: event.target.value });
  };

  const onMsgChange = (event) => {
    setContactState({ message: event.target.value });
  };

  const submitEmail = async (e) => {
    e.preventDefault();
    // axios({
    //   method: "POST",
    //   url:"/send",
    //   data:  this.state
    // }).then((response)=>{
    //   if (response.data.status === 'success'){
    //       alert("Message Sent.");
    //       this.resetForm()
    //   }else if(response.data.status === 'fail'){
    //       alert("Message failed to send.")
    //   }
    // })
  };

  const resetForm = () => {
    setContactState({ name: "", email: "", subject: "", message: "" });
  };

  return (
    <Container>
      <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        <br></br>
        <p>‎السلام عليكم ورحمة الله وبركاته</p>
        <p>طَلَبُ الْعِلْمِ فَرِيضَةٌ عَلَى كُلِّ مُسْلِمٍ</p>
        <p>
          “Seeking knowledge is an obligation upon every Muslim.” (Source: Sunan
          Ibn Mājah 224)
        </p>
        <p>
          In order to fulfil our duty to Allah we have a duty to seek knowledge.
          Learning is a life long activity and the seeking of knowledge is
          considered a blessed activity in the eyes of Allah.
        </p>
        <p>
          Whether you are someone who is interested in the beautiful religion of
          Islam, or some striving to learn more about Deen, we are here to
          answer your questions.
        </p>
        <p>
          This service allows you to ask questions regarding all aspects of
          Islam for individuals of all backgrounds.
        </p>
        <br></br>
        <br></br>
        <Title>ASK A QUESTION</Title>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <form id="contact-form" onSubmit={submitEmail}>
                  <div className="form-group">
                    <div className="row">
                      <div
                        className="col-md-6"
                        style={{ paddingBottom: "20px" }}
                      >
                        <TextField
                          placeholder="Name"
                          id="name"
                          type="text"
                          label="Name"
                          variant="outlined"
                          fullWidth
                          required
                          value={contactState.name}
                          onChange={onNameChange}
                        />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ paddingBottom: "20px" }}
                      >
                        <TextField
                          placeholder="Email"
                          id="email"
                          type="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          required
                          value={contactState.email}
                          onChange={onEmailChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group" style={{ paddingBottom: "20px" }}>
                    <TextField
                      placeholder="Message"
                      id="message"
                      multiline
                      rows={4}
                      label="Message"
                      variant="outlined"
                      fullWidth
                      required
                      value={contactState.message}
                      onChange={onMsgChange}
                    />
                  </div>
                  <Button variant="contained" type="submit" color="primary">
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AskImam;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 4% 4% 4% 4%;
`;
const GridWraper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1024px;
  margin: auto;
  grid-column-gap: 50px;
  padding: 0 10px;

  @media (max-width: 960px) {
    grid-template-columns: auto 1fr auto;
  }
`;

const DonationImage = styled.div`
  ${({ src }) =>
    src &&
    `
  background-image: url(${src});
  background-size: 185%;
    background-position: left bottom;
    border-radius: 4px;
  `}
`;

const Button = styled.button`
  padding: 10px;
  background: var(--base-color);
  border: 0;
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;
