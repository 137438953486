import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Http from "../helpers/Http";
import newsImage from "../images/news.png";
import eventImage from "../images/hadithIcon.png";
const dateFormat = require("dateformat");

const Template = (props) => {
  return (
    <Container>
      <Events data={props.data} image={props.image} type={props.type}/>
    </Container>
  );
};

export default Template;

const Events = ({ data, image, type }) => {
  return (
    <Grid>
      {data.map((item, key) => (
        <NewsContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <img src={image} width="100px" height="100px" />
            <NewsCard key={key}>
              <NewsTitle>{item.title.substring(0, 30) + "..."}</NewsTitle>
              {type === "event" ? (
                <p style={{ color: "#84ba40" }}>
                  on {dateFormat(item.eventDate, "mmmm dS, yyyy")}
                </p>
              ) : null}
              <NewsDate>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description.substring(0, 100)+"...",
                  }}
                />
              </NewsDate>
              <a
                href={`/detail/?id=${item.id}`}
                style={{ textDecoration: "none" }}
              >
                <Button>Read More</Button>
              </a>
            </NewsCard>
          </div>
        </NewsContainer>
      ))}
    </Grid>
  );
};

const Button = styled.button`
  position: absolute;
  padding: 10px;
  background: var(--base-color);
  border: 0;
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
  bottom: 0;
  display: flex;
  justifycontent: center;
  alignitems: center;
`;

const LiveSessionContainer = styled.div``;

const LiveSessionImage = styled.img`
  width: 100%;
  height: 254px;
  object-fit: cover;
  object-position: center;
`;

const LiveSessionTitle = styled.h2`
  font-size: 30px;
  text-transform: uppercase;
  margin: 10px 0;
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const LiveSessionTime = styled.time`
  font-size: 14px;
`;

const LiveSessionCard = styled.div`
  &:hover {
    ${LiveSessionTitle} {
      cursor: pointer;
      color: var(--base-color);
    }
  }
`;
const NewsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;
const NewsDate = styled.time``;

const NewsCard = styled.div`
  min-height: 250px;
  position: relative;
  border-bottom: 1px solid #fff;
  padding: 5px 20px;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    ${NewsTitle} {
      cursor: pointer;
      color: var(--base-color);
    }
  }
`;

const NewsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.025);
  padding: 14px 20px;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
