import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Http from "../helpers/Http";

const HadithOfTheDay = () => {
  const [hadith, setHadith] = useState({});
  useEffect(() => {
    try {
      Http.get("content/type/last/1/1")
        .then((response) => {
          setHadith(response.data.contents[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container>
      <Title>HADITH OF THE DAY</Title>
      <SubTitle>
        <div
          dangerouslySetInnerHTML={{
            __html: hadith.title,
          }}
        />
      </SubTitle>
      <Description>
        <div
          dangerouslySetInnerHTML={{
            __html: hadith.description,
          }}
        />
      </Description>
    </Container>
  );
};

export default HadithOfTheDay;

const Title = styled.h1`
  font-size: 50px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
`;

const SubTitle = styled.h3`
  text-transform: uppercase;
  font-size: 30px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
`;

const Description = styled.p`
  font-size: 20px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
`;

const Container = styled.div`
  flex: 1;
  border-radius: 5px;
  max-width: 1024px;
  margin: auto;
  background: rgba(0, 0, 0, 0.25);
  padding: 0 20px;

  @media (max-width: 480px) {
    ${Title} {
      font-size: 36px;
    }
    ${SubTitle} {
      font-size: 25px;
    }
  }

  @media (max-width: 375px) {
    
  }
`;
