import React, { useState, useEffect } from "react";
import styled from "styled-components";
import darwinImg from "../images/darwin.png";
import servicesIcon from "../images/servicesIcon.png";
import Http from "../helpers/Http";
import ReactModal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Template from "./Template";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 20 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    height: "50%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",

    transform: "translate(-50%, -50%)"
  },
}));

const MainServices = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    try {
      Http.get("content/type/2")
        .then((response) => {
          localStorage.setItem("services", JSON.stringify(response.data.contents));
          setServices(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container>
      <Template data={services} image={servicesIcon} type={"homepagevent"} />
    </Container>
  );
};

export default MainServices;

const ServiceCard = ({ item }) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

  return (
    <Card>
      <ImageContainer>
        <Image src={darwinImg} alt="darwin" />
        <Dashed />
      </ImageContainer>
      <CardTitle>{item.title}</CardTitle>
      <CardBody>
        <div
          dangerouslySetInnerHTML={{
            __html: item.description.substring(0, 200),
          }}
        />
      </CardBody>
      <CardLink href={`/detail/?id=${item.id}`}>
        Learn more...
      </CardLink>
    </Card>
  );
};

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  height: 64px;
  width: 64px;
`;
const Dashed = styled.div`
  flex: 1;
  border-bottom: 1px dashed #efefef;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  /* padding: 15px;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 2px 1.5px rgb(0 0 0 / 12%);
  } */
`;

const CardTitle = styled.h3`
  text-transform: uppercase;
  color: var(--base-color);
  font-size: 22px;
  margin: 0;
`;
const CardBody = styled.p`
  color: #9d9087;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
`;
const CardLink = styled.a`
  color: var(--base-color);
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
`;
