import * as React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import { Main } from "../styles";
import HeaderBackground from "../components/HeaderBackground";
import Header from "../components/Header";

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <HeaderBackground title={"CONTACT"} />
      <Main>
        <Contact />
      </Main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
