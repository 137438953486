import React from "react";
import styled from "styled-components";
import donation from "../images/donation.jpg";
import playstore from "../images/playstore.jpg";
import { isMobile } from "react-device-detect";

const DonatePlayStore = () => {
  return (
    <Container>
      <form
        action="https://www.paypal.com/donate"
        method="post"
        target="_top"
      >
        <input type="hidden" name="hosted_button_id" value="SG93BXEVF55Y2" />
        <input
          type="image"
          src={donation}
          style={{ width: "100%",
          objectFit: "contain",
          objectPosition: "center",
          borderRadius: "6px"}}
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Donate with PayPal button"
        />
        <img
          alt=""
          border="0"
          src="https://www.paypal.com/en_GB/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
      <Image src={playstore} alt="playstore" />
    </Container>
  );
};

export default DonatePlayStore;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 6px;
`;

const Container = styled.div`
  background-color: #fff;
`;
const GridWraper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1024px;
  margin: auto;
  grid-column-gap: 0px;
  padding: 0 10px;

  @media (max-width: 960px) {
    grid-template-columns: auto 1fr auto;
  }
`;

const DonationImage = styled.div`
  ${({ src }) =>
    src &&
    `
  background-image: url(${src});
  background-size: 185%;
    background-position: left bottom;
    border-radius: 4px;
  `}
`;

const Button = styled.button`
  padding: 10px;
  background: var(--base-color);
  border: 0;
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;
