import * as React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import News from "../components/News";
import { Main } from "../styles";
import HeaderBackground from "../components/HeaderBackground";
import Header from "../components/Header";

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <HeaderBackground title={"EVENTS"} />
      <Main>
        <News />
      </Main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
