import * as React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import TemplateDetail from "../components/TemplateDetail";
import { Main } from "../styles";
import HeaderBackground from "../components/HeaderBackground";
import Header from "../components/Header";
import Http from "../helpers/Http";

const IndexPage = ({location}) => {

  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  const [service, setService] = React.useState({});
  React.useEffect(() => {
    try {
      Http.get(`content/${id}`)
        .then((response) => {
          setService(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  
  return (
    <Layout>
      <Header />
      <HeaderBackground title={"SERVICE"} />
      <Main>
        <TemplateDetail service={service} />
      </Main>
      <Footer />
    </Layout>
  );
};;

export default IndexPage;
