import React from "react";
import styled from "styled-components";
import FajrComponent from "../assets/Asset4.svg";
import SunriseComponent from "../assets/Asset5.svg";
import ZuhrComponent from "../assets/Asset2.svg";
import AsrComponent from "../assets/Asset6.svg";
import MaghribComponent from "../assets/Asset1.svg";
import IshaComponent from "../assets/Asset3.svg";

const PrayerTable = (props) => {
  const { prayerData } = props;

  const convertTime = (time) => {
    return new Date("1970-01-01T" + time + "Z").toLocaleTimeString(
      {},
      {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      }
    );
  }

  return (
    <TableConainer>
      <Table>
        <TBody>
          <Tr>
            <Td>Prayer</Td>
            <Td></Td>
            <Td>Begins</Td>
            <Td>Jamaat</Td>
          </Tr>
          <Tr>
            <Td>Fajr</Td>
            <Td>
              <div style={{ height: "17px", width: "25px" }} className="size">
                <FajrComponent />
              </div>
            </Td>
            <Td>{convertTime(prayerData.fajrBegins)}</Td>
            <Td>{convertTime(prayerData.fajrJamah)}</Td>
          </Tr>
          <Tr>
            <Td>Sunrise</Td>
            <Td>
              <div style={{ height: "20px", width: "30px" }} className="size">
                <SunriseComponent />
              </div>
            </Td>
            <Td colSpan={2}>{convertTime(prayerData.sunrise)}</Td>
          </Tr>
          <Tr>
            <Td>Zuhr</Td>
            <Td>
              <div style={{ height: "17px", width: "25px" }} className="size">
                <ZuhrComponent />
              </div>
            </Td>
            <Td>{convertTime(prayerData.zuhrBegins)}</Td>
            <Td>{convertTime(prayerData.zuhrJamah)}</Td>
          </Tr>
          <Tr>
            <Td>Asr</Td>
            <Td>
              <div style={{ height: "17px", width: "25px" }} className="size">
                <AsrComponent />
              </div>
            </Td>
            <Td>{convertTime(prayerData.asrMithl_1)}</Td>
            <Td>{convertTime(prayerData.asrJamah)}</Td>
          </Tr>
          <Tr>
            <Td>Maghrib</Td>
            <Td>
              <div style={{ height: "20px", width: "30px" }} className="size">
                <MaghribComponent />
              </div>
            </Td>
            <Td>{convertTime(prayerData.maghribBegins)}</Td>
            <Td>{convertTime(prayerData.maghribJamah)}</Td>
          </Tr>
          <Tr>
            <Td>Isha</Td>
            <Td>
              <div style={{ height: "15px", width: "20px" }} className="size">
                <IshaComponent />
              </div>
            </Td>
            <Td>{convertTime(prayerData.ishaBegins)}</Td>
            <Td>{convertTime(prayerData.ishaJamah)}</Td>
          </Tr>
        </TBody>
      </Table>
    </TableConainer>
  );
};

export default PrayerTable;

const TableConainer = styled.div``;
const Table = styled.table`
  width: 100%;
  max-width: 400px;
  margin: 32px auto;
  border-collapse: collapse;
`;
const TBody = styled.tbody``;
const Tr = styled.tr``;
const Td = styled.td`
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
`;
