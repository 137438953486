import React from "react";
import styled from "styled-components";
import backgroundImage from "../images/backgroundHeader.jpeg";

const HeaderBackground = (props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: "300px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Title>{props.title}</Title>
    </div>
  );
};

export default HeaderBackground;

const Title = styled.h1`
  font-size: 60px;
  margin: 0;
  color: #fff;
`;
