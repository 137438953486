import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Http from "../helpers/Http";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import Template from "./Template";
import eventIcon from "../images/event.png";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 20 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "scroll",

    transform: "translate(-50%, -50%)",
  },
  title: {
      display: "flex",
      justifyContent: "space-between"
  }
}));

const ProgramsAndEvent = () => {
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState([]);
  useEffect(() => {
    try {
      Http.get("content/type/3")
        .then((response) => {
          setNews(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
      Http.get("content/type/4")
        .then((response) => {
          setEvents(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
     <Container>
      <Template data={events} image={eventIcon} type={"event"}/>
    </Container>
  );
};

export default ProgramsAndEvent;

const Events = ({ events }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
  return (
    <NewsContainer>
      {events.map((item, key) => (
        <NewsCard key={key}>
          <NewsTitle onClick={handleOpen}>{item.title}</NewsTitle>
          <NewsDate>
            <div
              dangerouslySetInnerHTML={{
                __html: item.description.substring(0, 200) + "...",
              }}
            />
          </NewsDate>
          <NewsDate>Event Date: {item.eventDate}</NewsDate>
          <br></br>
          <NewsLink>
            <a onClick={handleOpen}>Read More</a>
          </NewsLink>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.paper}>
              <div className={classes.title}>
                <h2 id="simple-modal-title">{item.title}</h2>
                <IconButton onClick={handleClose}>
                  <CancelIcon />
                </IconButton>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </div>
          </Modal>
        </NewsCard>
      ))}
    </NewsContainer>
  );
};

const Button = styled.button`
  padding: 10px;
  background: var(--base-color);
  border: 0;
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
`;

const LiveSessionContainer = styled.div``;

const LiveSessionImage = styled.img`
  width: 100%;
  height: 254px;
  object-fit: cover;
  object-position: center;
`;

const LiveSessionTitle = styled.h2`
  font-size: 30px;
  text-transform: uppercase;
  margin: 10px 0;
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const LiveSessionTime = styled.time`
  font-size: 14px;
`;

const LiveSessionCard = styled.div`
  &:hover {
    ${LiveSessionTitle} {
      cursor: pointer;
      color: var(--base-color);
    }
  }
`;

const NewsTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const NewsLink = styled.p`
  font-size: 16px;
`;

const NewsDate = styled.time``;

const NewsCard = styled.div`
  border-bottom: 1px solid #fff;
  padding: 5px 0;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    ${NewsTitle} {
      cursor: pointer;
      color: var(--base-color);
    }
  }
  &:hover {
    ${NewsLink} {
      cursor: pointer;
      color: var(--base-color);
    }
  }
`;

const NewsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.025);
  padding: 24px 40px;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
