import React from "react";
import styled from "styled-components";
import Aasim from "../images/aasim.jpeg";
import Imran from "../images/ImranAhmedPicture.jpeg";
import Umair from "../images/umair.jpeg";
import DailyPrayerTimes from "./DailyPrayerTimes";

const imageContent = [
  { name: "IMRAN AHMED", position: "Chairman", image: Imran },
  { name: "AASIM KHAN", position: "Treasurer", image: Aasim },
  { name: "UMAIR FAROOQ", position: "Secretary", image: Umair }
];

const TemplateDetail = ({ service }) => {
  console.log("service: ", service);
  return (
    <Container>
      <div style={{ marginTop: "10px" }}>
        <Grid>
          <div>
            <Title>{service.title}</Title>
            <Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: service.description,
                }}
              />
            </Content>
          </div>
          <div>
            <DailyPrayerTimes />
          </div>
        </Grid>
      </div>
    </Container>
  );
};;

export default TemplateDetail;

const Image = ({ name, image, position }) => {
  return (
    <NewsContainer>
        <img src={image} width="300px" height="300px"/>
        <ImageTitle>{name}</ImageTitle>
        <SubTitle>{position}</SubTitle>
    </NewsContainer>
  );
};

const NewsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.025);
  padding: 12px 20px;
`;

const NewsCard = styled.div`
  border-bottom: 1px solid #fff;
  padding: 5px 0;
  &:last-child {
    border-bottom: 0;
  }
`;

const ImageTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const SubTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  padding-top: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 4% 4%;
`;
const GridWraper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1024px;
  margin: auto;
  grid-column-gap: 50px;
  padding: 0 10px;

  @media (max-width: 960px) {
    grid-template-columns: auto 1fr auto;
  }
`;

const Title = styled.h3`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const Content = styled.p`
  marginBottom: 20px;
  font-size: 18px;
`;
